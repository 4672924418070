import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const TechAdLevtechCollege = () => {
  return (
    <div className='tech-ad-levtech-college'>
      <h2>大学生へのお知らせ</h2>
      <p>ここまで記事を読んでくれて本当に嬉しいです、ありがとうございます。</p>
      <p>プログラミングを始めたばかりの頃って「やるぞ！」という気持ちは強めですが、
        実際に取り組み始めると「何からどうやってどこまでやればいんだ？」
        という感じで羅針盤を失うことが多いような気がします。(実体験)
      </p>
      <p>そんな勉強熱心で向上心高めなんだけど目指す姿が曖昧な大学生の方に感謝の意も込めて、
        <b>
          <a href='//af.moshimo.com/af/c/click?a_id=3419053&p_id=3198&pc_id=7488&pl_id=53040&url=https%3A%2F%2Fac.ebis.ne.jp%2Ftr_set.php%3Fargument%3D2uzLfYxA%26ai%3Da5fd6b7ce4f698'>
            就職活動までサポートするプログラミングスクール「レバテックカレッジ」
          </a>
        </b>
        を紹介します。↓
      </p>
      <a href='//af.moshimo.com/af/c/click?a_id=3419053&p_id=3198&pc_id=7488&pl_id=53040&url=https%3A%2F%2Fac.ebis.ne.jp%2Ftr_set.php%3Fargument%3D2uzLfYxA%26ai%3Da5fd6b7ce4f698'>
        <StaticImage
          src='https://image.moshimo.com/af-img/0304/000000053045.jpg'
          alt='Levtech-college'
        />
      </a>
      <p>「自走力」のあるエンジニアを最短3ヶ月で目指せるカリキュラムが月額30,000円で用意されていて、
        現段階の「目指す姿が曖昧で何から手をつければいいかわからない状態」から脱却できます。
      </p>
      <p>自分は実際にIT企業で働いていますが、「自走力」のあるエンジニアの方にはいつも助けられています。</p>
      <p>なので、目指す姿が曖昧な人はとりあえずレバテックカレッジが導いてくれる
        「自走力のあるエンジニア」を目指してみるのはスタートダッシュとしてはアリです。
      </p>
      <p>レベルアップしてからジョブチェンするのはRPGでも常套手段ですし、
        レバテックカレッジで一通りのスキルを習得してから自分の目指す姿を再定義するといいかもです。
      </p>
      <p>「自走力のあるエンジニア」って実際どんなエンジニアなの？
        何から手をつければいいかわからないから教えて欲しい、
        という人は上のテキストリンク/画像リンクから「無料相談会」への申し込みをお願いします。
      </p>
      <p>よきエンジニアライフを！</p>
    </div>
  )
}

export default TechAdLevtechCollege
