import * as React from 'react'
import { Link } from 'gatsby'

const BlogPostTags = props => {
  const tags = props.tags.map(tag =>
    <div
      className='blog-post-tags'
      key={`k_${tag}`}
    >
      <small>
        <Link
          to={`/tags/${tag}`}
          className='blog-post-tags'
        >
          #{tag}
        </Link>
      </small>
    </div>
  )

  return (
    <>
      {tags}
    </>
  )
}

export default BlogPostTags
