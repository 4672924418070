import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

const BlogPostBio = () => {
  const data = useStaticQuery(graphql`
    query BlogPostBioQuery {
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className='blog-post-bio'>
      <StaticImage
        className='blog-post-bio-avatar'
        layout='constrained'
        formats={['AUTO', 'WEBP', 'JPEG', 'PNG', 'AVIF']}
        src='../images/icon.png'
        width={25}
        height={25}
        quality={50}
        alt='Profile picture'
      />
      <small>
        {author.name}
      </small>
      <a
        className='bio-fontawesome twitter'
        href={`https://twitter.com/${social.twitter}`}
      >
        <FontAwesomeIcon
          icon={faTwitter}
        />
      </a>
    </div>
  )
}

export default BlogPostBio
