import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const TechAdTechgymPython = () => {
  return (
    <div className='tech-ad-techgym-python'>
      <h2>Python 初学者へのお知らせ</h2>
      <p>約2時間で Python の基礎を学び、簡単なゲームを作ってみませんか？</p>
      <p>そんな方に、Python の基本を実践的に学びたい人向けの講座、
        <b>
          <a href='//af.moshimo.com/af/c/click?a_id=3448889&p_id=3404&pc_id=8110&pl_id=48216&url=https%3A%2F%2Ftechgym.jp%2F%3Fp%3D19'>
            【無料】ゼロから始めるPythonプログラミング入門講座（週4開催）
          </a>
        </b>
        をお勧めします。
      </p>
      <a href='//af.moshimo.com/af/c/click?a_id=3448889&p_id=3404&pc_id=8110&pl_id=48216&url=https%3A%2F%2Ftechgym.jp%2F%3Fp%3D19'>
        <StaticImage
          src='https://image.moshimo.com/af-img/2083/000000048216.png'
          alt='tech-ad-techgym-python'
        />
      </a>
      <p>この講座で、無料でゲーム開発を通してプログラミングの基礎を身につけてみませんか？</p>
      <p>少しでも気になった方は、上記のテキストリンクもしくは画像リンクから申し込みお願いします！</p>
      <p>Python との楽しい日々をお過ごしください：）</p>
    </div>
  )
}

export default TechAdTechgymPython
