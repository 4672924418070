import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const shuffle = (posts) => {
  for (let i = posts.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const k = posts[i]
    posts[i] = posts[j]
    posts[j] = k
  }
  return posts
}

const BlogPostRelated = ({ post }) => {
  let related_post_counter = 0
  const related_post = []
  const categories = ['tech', 'life', 'science', 'etc']
  const data = useStaticQuery(RelatedPostsQuery)
  const {
    allMarkdownRemark: {
      edges
    }
  } = data

  const posts = shuffle(edges)

  return (
    <>
      <h3>
        関連記事
      </h3>
      <ul>
        {posts.map((edge, increment) => {
          const title = edge.node.frontmatter.title
          const slug = edge.node.frontmatter.slug
          const tags = edge.node.frontmatter.tags
          for (const tag of post.frontmatter.tags) {
            if (tags.indexOf(tag) !== -1 &&
            categories.indexOf(tag) === -1 &&
            related_post.indexOf(slug) === -1 &&
            post.frontmatter.slug !== slug &&
            related_post_counter < 3) {
              related_post_counter++
              related_post.push(slug)
              return (
                <li key={slug}>
                  <Link to={slug}>
                    {title}
                  </Link>
                </li>
              )
            }
          }
          if (increment === posts.length - 1 &&
          !related_post_counter) {
            return 'なし'
          }
        })}
      </ul>
    </>
  )
}

export default BlogPostRelated

const RelatedPostsQuery = graphql`
{
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          slug
          tags
          title
        }
      }
    }
  }
}
`
