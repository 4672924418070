import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogPostTags from '../components/blog-post-tags'
import BlogPostBio from '../components/blog-post-bio'
import BlogPostRelated from '../components/blog-post-related'
import TechAdLevtechCollege from '../components/tech-ad-levtech-college'
import TechAdTechgymPython from '../components/tech-ad-techgym-python'

import 'prismjs/themes/prism-okaidia.css'
import 'katex/dist/katex.min.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const image = data.markdownRemark.frontmatter.socialImage
    ? getImage(data.markdownRemark.frontmatter.socialImage.childImageSharp.gatsbyImageData)
    : null
  const imageCard = data.markdownRemark.frontmatter.socialImage
    ? data.markdownRemark.frontmatter.socialImage.childImageSharp.resize
    : null
  const siteTitle = data.site.siteMetadata?.title || 'Title'
  const { previous, next } = data
  const tags = data.markdownRemark.frontmatter.tags || null
  const hasTechTag = (tags) => {
    tags.map(tag => {
      if (tag === 'tech') {
        return 'true'
      } else {
        return 'false'
      }
    })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={imageCard}
      />
      <article
        className='blog-post'
        itemScope
        itemType='http://schema.org/Article'
      >
        <header>
          {image &&
            <GatsbyImage
              image={image}
              alt='eyecatch thumbnail'
            />}
          <h1 itemProp='headline'>
            {post.frontmatter.title}
          </h1>
          <BlogPostBio />
          <div>
            <small>
              {post.frontmatter.date}
            </small>
          </div>
          <BlogPostTags tags={post.frontmatter.tags} />
        </header>
        <hr />
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp='articleBody'
        />
        <hr />
        <BlogPostRelated post={post} />
        <hr />
        {/* Add TechAdTechgymPython */}
        {tags.map(tag => {
          if (tag == 'python') {
            return (<TechAdTechgymPython />)
          } else {
            return (null)
          }
        })}
        {/* Add TechAdLevtechCollege */}
        {tags.map(tag => {
          if (tag === 'tech') {
            return (<TechAdLevtechCollege />)
          } else {
            return (null)
          }
        })}
      </article>
      <nav className='blog-post-nav' />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        slug
        date(formatString: "YYYY.MM.DD")
        description
        tags
        socialImage {
          childImageSharp {
            gatsbyImageData
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
